<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('goals.view')">
          <actions
            slot="actions"
            crud-links="goals"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list :title="$t('tables.headings.period')">
            {{ goalPeriod }}
          </text-list>
          <text-list :title="$t('tables.headings.type')">
            {{ $t(goalType) }}
          </text-list>
          <text-list :title="$t('tables.headings.value')">
            {{ goal.value }}
          </text-list>
          <text-list :title="$t('tables.headings.model')">
            {{ modelLabel(goal.model) }}
          </text-list>
          <text-list :title="$t('tables.headings.associated')">
            {{ goal.alias }}
          </text-list>
          <text-list
            v-if="goal.user"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.user')"
            :label="goal.user.name"
            :to="{ name: 'usersView', params: {id: goal.user.id}}"
          />
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'goals-view',
  components: {
    Actions, TextList,
  },
  data () {
    return {
      goal: {},
      error: false,
      loading: false,
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    goalPeriod () {
      let period = ''
      if (this.goal && this.goal.year) {
        const year = Number(this.goal.year)
        period = `${year} - ${year + 1}`
      }
      return period
    },
    goalType () {
      let type = 'tables.undefined'
      if (this.goal) {
        switch (this.goal.type) {
          case 0: type = 'goals.types.predication'; break
        }
      }

      return type
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    modelLabel (model) {
      const translate = `goals.models.${model}`
      return this.$t(translate)
    },
    routeBuilder (id) {
      return `goals/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const goalId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(goalId))
      } catch (err) {
        // console.log('Error fetching goal data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.goal = u.data.data
    },
  },
}
</script>
